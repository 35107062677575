// Set these variables via environment variables in development,
// replace the placeholder strings at container startup in production

const DEBUG = import.meta.env.DEBUG || '##PROD_DEBUG##'
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || '/api/v1'
const CDN_BASE_URL = import.meta.env.VITE_CDN_BASE_URL || '##PROD_CDN_BASE_URL##'
const FRONTEND_VERSION = import.meta.env.VITE_FRONTEND_VERSION || '##FRONTEND_VERSION##'

const environment = import.meta.env.NODE_ENV

const env = {
  current: environment,
  isDebug: DEBUG === 'enabled',
  isDevelop: import.meta.env.DEV,
  isProduction: import.meta.env.PROD,
  APIBaseURL: API_BASE_URL,
  CDNBaseURL: CDN_BASE_URL,
  frontendVersion: FRONTEND_VERSION
}

const dateFormatStandard = 'yyyy-MM-dd'
const dateFormatStandardWithTime = 'yyyy-MM-dd HH:mm'
const dateFormatHuman = 'eeee dd/MM/yy, HH:mm'
const dateFormatHumanCompact = 'dd/MM/yy'

const localStoragePrefix = 'refrion.ST.'

const localStorageKeys = {
  actions: `${localStoragePrefix}actions`,
  authToken: `${localStoragePrefix}authToken`,
  cs: `${localStoragePrefix}cs`,
  ccs: `${localStoragePrefix}ccs`,
  pcs: `${localStoragePrefix}pcs`,
  currentLanguage: `${localStoragePrefix}currentLanguage`,
  roles: `${localStoragePrefix}roles`,
  systemNotifications: `${localStoragePrefix}systemNotifications`,
  unitSystem: `${localStoragePrefix}unitSystem`,
  user: `${localStoragePrefix}user`
}

const pdfOptions = {
  // format: 'A4',
  displayHeaderFooter: true,
  headerTemplate: '<div/>',
  // footerTemplate: '<div style="margin: 0 30px 12px; text-align: right; width: 100%; font-size: 7px; font-family: \'Yantramanav\', sans-serif; color: #7a7a7a;"><span class="pageNumber"></span>/<span class="totalPages"></span></div>',
  footerTemplate: '<div style="text-align: right; width: 100%; margin: 0 7mm; height: 7px; line-height: 7px; font-size: 7px; font-family: \'Yantramanav\', sans-serif; color: #7a7a7a;"><span class="pageNumber"></span>/<span class="totalPages"></span></div>',
  // margin: {
  //   top: '60px',
  //   bottom: '50px',
  //   right: '15px',
  //   left: '40px'
  // }
  margin: {
    top: '12mm',
    right: '10mm',
    bottom: '12mm',
    left: '10mm'
  }
}

const customFansIdsOffset = 100000

const sentryDSN = 'https://4f83243366efba777e925026e94827b2@sentry.mvlabs.it/32'

const currentVersion = '3.3.0' // TODO automate the version bump

export {
  env,
  dateFormatStandard,
  dateFormatStandardWithTime,
  dateFormatHuman,
  dateFormatHumanCompact,
  localStorageKeys,
  currentVersion,
  pdfOptions,
  customFansIdsOffset,
  sentryDSN
}
